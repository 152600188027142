


























import {
  Component,
  Vue
} from "vue-property-decorator";
import {
  api
} from '@/api'
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  onScroll
} from "@/utils/func";
import {
  ChatModule
} from "@/store/modules/chat";
import {
  MsgType,
  TaskMsgType
} from "@/utils/interface"

@Component({
  name: 'select-dialogue',
})
export default class SelectDialogue extends Vue {
  private sessionList: any = []
  private hasNextPage = true
  private pageNo = 1
  private pageSize = 20
  private isRequest = false
  private selectWxStrId: string[] = []
  private selectWxId: number[] = []
  private defAva: string[] = ['https://oss.guojiangmedia.com/wxbackend/default-avatar.png',
    'https://oss.guojiangmedia.com/planr/chat-room-icon2.png'
  ]
  private loadingVal: any = null
  private sendIndex: number = 1

  private get wxId(): number {
    return WechatModule.wxId
  }

  private get selectChat(): any {
    return ChatModule.selectChatList
  }

  private get deviceId(): number {
    return WechatModule.deviceId
  }
  private mounted() {
    this.sessionList = []
    this.getSessionList()
  }

  /**
   * @func 监听滚动
   */
  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollUp: () => {
        if (this.sessionList.length > 0) {
          this.getSessionList()
        }
      }
    })
  }

  /**
   * @func 获取会话列表
   */
  private getSessionList() {
    if (this.isRequest) return
    if (!this.hasNextPage) return
    this.isRequest = true
    const params: any = {
      wechat_id: this.wxId,
      page_no: this.pageNo,
      page_size: this.pageSize
    }
    api.getFriendForMsgList(params).then((res: any) => {
      !res.list || res.list.lenth < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++;
      if (res.list) {
        res.list.forEach((cov: any) => {
          if (!cov.chat_type) {
            cov.contact.chat_type = cov.chat_type
            this.sessionList.push(cov.contact)
          } else {
            cov.chat_room.chat_type = cov.chat_type
            this.sessionList.push(cov.chat_room)
          }
        })
      }
      console.log(this.sessionList)
      this.isRequest = false
    }).catch((err: any) => {
      this.isRequest = false
    })
  }

  /**
   * @func 点击会话
   * @param data 会话用户信息
   */
  private clickItem(data: any) {
    let index = this.selectWxStrId.indexOf(data.wx_src_id || data.username)
    if (index >= 0) {
      this.selectWxStrId.splice(index, 1)
      this.selectWxId.splice(index, 1)
    } else {
      this.selectWxStrId.push(data.wx_src_id || data.username)
      this.selectWxId.push(data.id)
    }
    console.log(this.selectWxStrId)
  }

  /**
   * @func 跳转到好友或者群选择页面
   */
  private toNewChat() {
    this.$router.push({
      name: 'Friends'
    })
  }

  private toBack() {
    this.$router.go(-1)
  }

  /**
   * @func 循环转发消息
   */
  private sendMsgLoop(item: any) {
    if (this.selectWxStrId.length === 1) {
      this.loadingVal = this.$weui.loading('发送中...');
      this.sendIndex = 0
      let arr: any = []
      for (let key in this.selectChat) {
        arr.push(this.selectChat[key])
      }
      arr.sort((a: any,b: any) => {
        return a.record_time - b.record_time
      })
      let chatidx = 0
      let timeout = setInterval(() => {
        if (chatidx === arr.length) {
          clearInterval(timeout)
        } else {
          this.sendMsg(this.assembleMsgData(arr[chatidx]), this.selectWxId[0])
        }
        ++chatidx
      }, 1500)
    } else if (this.selectWxStrId.length > 1) {
      this.loadingVal = this.$weui.loading('发送中...');
      let msgList: any = []
      for (let key in this.selectChat) {
        let msg: any = this.selectChat[key]
        msgList.push(this.assembleMsgData(msg).task_single_msg)
      }
      this.massTask(this.selectWxStrId, msgList)
    } else {
      this.$weui.topTips('请选择要发送的好友或群')
      return
    }
  }

  /**
   * @func 组装发送消息
   */
  private assembleMsgData(msg: any) {
    const param: any = {
      task_time: 0,
      task_single_msg: {}
    }

    switch (msg.msg_type) {
      case MsgType.msg_type_text:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_text,
          content: msg.content
        }
        break;
      case MsgType.msg_type_img:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_image,
          content: msg.content
        }
        break;
      case MsgType.msg_type_video:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_video,
          content: msg.content
        }
        break;
      case MsgType.msg_type_emoji:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_image,
          content: msg.content
        }
        break;
      case MsgType.msg_type_gif:
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_gif,
          content: msg.content
        }
        break;
      case MsgType.msg_type_link:
        let content = JSON.parse(msg.content).appmsg;
        param.task_single_msg = {
          task_type: TaskMsgType.msg_send_link,
          title: content.title,
          desc: content.des,
          url: content.url,
          pic: 'https://oss.guojiangmedia.com/sales-order-backend%2Fdefault_link.png'
        }
        break;
    }
    return param
  }

  /**
   * @func 发送消息请求 (当只选中一个好友)
   * @param data 请求数据
   */
  private async sendMsg(data: any, friend_id: number) {
    try {
      const res: any = await api.sendSingleMessage({
        wechat_id: this.wxId,
        task: data,
        friend_id: friend_id,
        friend_type: this.selectWxStrId[0].indexOf('chatroom') !== -1 ? 1 : 0
      })
      this.sendIndex++
      console.log(this.sendIndex, Object.keys(this.selectChat).length)
      if (this.sendIndex === Object.keys(this.selectChat).length) {
        this.loadingVal.hide()
        this.loadingVal = null
        this.toBack()
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 群发消息 （当选中一个以上的好友）
   */
  private async massTask(wxids: string[], msgs: any) {
    try {
      await api.massTask({
        wechat_id: this.wxId,
        task: {
          wechat_id: this.wxId,
          task_time: 0,
          remark: '转发任务' + new Date(),
          wxids: wxids,
          mass_interval: 3,
          msgs: msgs
        }
      })
      this.$weui.topTips('创建成功', {
        className: 'success-tips'
      })
      this.loadingVal.hide()
      this.loadingVal = null
      this.toBack()
    } catch (err) {
      console.log(err)
    }
  }
}
